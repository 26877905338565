$spacer: 1rem;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: false;
$enable-prefers-reduced-motion-media-query: true;
$enable-responsive-font-sizes: true;

$font-weight-bold: 800;
$font-weight-normal: 400;
$font-weight-light: 200;

$font-size-lg: 24px;
$font-size-md: 16px;
$font-size-sm: 12px;

$font-color: #000000;
$font-muted: #808080;
//https://tailwindcss.com/docs/customizing-colors

.ef-theme {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Source Sans Pro', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $font-weight-normal;
  color: $font-color;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tw-rounded-full {
    border-radius: 9999px;
  }

  .input-error,
  .input-error:focus,
  .input-error:focus-visible {
    border: #d0342c 2px solid;
    outline: none;
    box-shadow: 1px 1px 5px #d0342c77;
  }

  --color-white: #fff;
  --color-background: #fff;

  --color-primary: #3a7d90;
  --color-primary-hover: #135669;
  --color-secondary: #727272;
  --color-secondary-hover: #4a575f;

  --color-selected: #edf2ff;
  // General colors
  --color-success: #428254;
  --color-error: #c2410c;

  --color-lightgray: #f5f5f5;
  --color-darkgray: #323232;

  --color-progressBarText: var(--color-primary);

  --color-primaryButtonText: #fff;
  --color-secondaryButtonText: #fff;

  --border-radius: 0;
}
