.woody {
  --color-primary: #004ea0;
  --color-primary-hover: #266ab2;
}
.fellessons {
  --color-primary: #702d26;
  --color-primary-hover: #8b5650;
}
.materialmannen {
  --color-primary: #ffff00;
  --color-primary-hover: #6e6c69;

  --color-progressBarText: #000;
  --color-secondary: #000;
  --color-secondary-hover: #6e6c69;
  --color-primaryButtonText: #000;
  --color-secondaryButtonText: #fff;
}
.bruks {
  --color-primary: #50afc9;
}
