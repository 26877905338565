.ProductScroller {
  .product-list {
    display: flex;
    overflow-x: auto;
    li {
      margin-bottom: 0;
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
}
